@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #EBA050;
}

body {
  margin: 0;
  font-family: "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.indicator {
  cursor: pointer !important;
  padding: 4px !important;
  text-align: center !important;
  margin: 0 !important;
  border-radius: 100% !important;
  margin-left: 3px !important;
  margin-right: 3px !important;
  margin-top: -35px !important;
  margin-bottom: 35px !important;
  position: relative !important;
  background-color: #fff !important;
}

.indicator.active {
  color: #fff !important;
  background: var(--primary-color) !important;
}


.ant-input {
  font-size: 16px;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-radius: 10px !important;
}

.ant-input:focus,
.ant-input:hover {
  border-color: var(--primary-color) !important;
}

.ant-select-selector {
  height: 40px !important;
  padding-top: 4px !important;
}

.ant-select-selection-search-input {
  height: 38px !important;
}

.ant-btn {
  font-size: 16px;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  height: max-content !important;
  border-radius: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-btn-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.ant-input-group-addon {
  border-radius: 0px !important;
}

.ant-popconfirm-buttons {
  display: flex !important;
  justify-content: center !important;
}

.ant-popover-inner {
  margin-left: 10px !important;
}

.ant-table-thead > tr > th {
  background-color: var(--primary-color) !important;
  color: white !important;
}